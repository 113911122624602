<template>
  <div class="member_show">
    <div class="show_title">
      <img src="img/account/icon_title_6.svg" alt="">
      <h4 class="txt-bold">我的通知</h4>
    </div>

    <div class="show_notice">
      <!-- <div class="tagset">
        <a class="tag txt-bold active">
          <p>全部</p>
        </a>
        <a class="tag txt-bold">
          <p>訂單</p>
        </a>
        <a class="tag txt-bold">
          <p>專屬</p>
        </a>
        <a class="tag txt-bold">
          <p>活動</p>
        </a>
        <a class="tag txt-bold">
          <p>公告</p>
        </a>
      </div> -->

      <div class="notice_box">
        <!-- 未讀訊息加上class="unread" -->
        <a
          :href="item.link"
          v-for="(item) in noticeData"
          :key="`notice${item.id}`"
          target="_blank"
          class="notice_item"
          :class="{ unread: item.read == 0 }"
          @click="changeMsgStatus(item.id)">
            <p class="date txt-greenlake">{{item.created_at}}</p>
            <p class="classtag">{{item.type}}</p>
            <h6>{{item.content}}</h6>
        </a>
      </div>

      <!-- <Paginate/> -->
    </div>

  </div>
</template>

<script>
// import Paginate from '@/components/Paginate.vue';
import requestApi from '@/lib/http';

export default {
  name: 'MemberNotice',
  components: {
    // Paginate,
  },
  data() {
    return {
      noticeData: [],
    };
  },
  methods: {
    async getNotifies() {
      const { data, message } = await requestApi('member.getNotifies');

      if (data) {
        data.forEach((element) => {
          this.noticeData.push(element);
        });
      } else {
        this.$customSWAL({
          icon: 'error',
          title: message,
        });
      }
    },
    async changeMsgStatus(id) {
      await requestApi('member.changeMsgStatus', {
        id,
      });
    },
  },
  mounted() {
    this.getNotifies();
  },
};
</script>
